@font-face {
  font-family: 'Gilroy';
  // src: url('../fonts/Gilroy-BoldItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-Bold.woff') format('woff');
  // src: url('../fonts/Gilroy-BlackItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-Black.woff') format('woff');
  // src: url('../fonts/Gilroy-Regular.woff') format('woff');
  // src: url('../fonts/Gilroy-Semibold.woff') format('woff');
  // src: url('../fonts/Gilroy-SemiboldItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-Thin.woff') format('woff');
  // src: url('../fonts/Gilroy-ThinItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-UltraLight.woff') format('woff');
  // src: url('../fonts/Gilroy-UltraLightItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-MediumItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-Medium.woff') format('woff');
  // src: url('../fonts/Gilroy-LightItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-Light.woff') format('woff');
  // src: url('../fonts/Gilroy-Heavy.woff') format('woff');
  // src: url('../fonts/Gilroy-ExtraboldItalic.woff') format('woff');
  // src: url('../fonts/Gilroy-HeavyItalic.woff') format('woff');
  src: url('../fonts/Gilroy-Regular.woff') format('woff');
}
