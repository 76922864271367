@use 'variables';

//стили конструктора для быстрой стилизации
// флекс обертка
.f-box.f-inline {
  display: flex;
  &_row {
    flex-direction: row;
  }
  &_column {
    flex-direction: column;
  }
}
// переопределяем для инлаин
.f-inline {
  display: inline-flex;
}
