@import 'media';

.subtitle {
  font-size: 18px;

  @include tablet-md {
    font-size: 16px;
  }
}

.tes-fieldset-feedback {
  font-size: 0.7625rem;

  @include tablet-xl {
    font-size: 14px;
  }

  @include mobile-xl {
    font-size: 12px;
  }

  @include mobile-mb {
    font-size: 11px;
  }
}

.change-link {
  font-size: 16px;

  @include tablet-xl {
    font-size: 14px;
  }

  @include mobile-mb {
    font-size: 12px;
  }
}

.element-wrapper {
  display: flex;
  justify-content: flex-start;

  @include tablet-md {
    display: flex;
    justify-content: center;
  }
}

.text-secondary {
  @include tablet-xl {
    font-size: 14px;
  }

  @include mobile-mb {
    font-size: 11px;
  }
}

.text-danger {
  @include tablet-xl {
    font-size: 12px;
  }

  @include mobile-xl {
    font-size: 10px;
  }
}

.tes-value-readonly {
  @include tablet-xl {
    font-size: 14px;
  }

  @include mobile-xl {
    font-size: 12px;
  }
}

.tes-fieldset {
  @include tablet-md {
    margin: 0 auto;
  }
}
