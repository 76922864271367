@use 'variables';

/* Styles for tab */
.mat-tabs-broker {
  .mat-ink-bar {
    background-color: white !important;
  }

  .mat-tab-label {
    color: $c-light-gray;
    width: 100%;
  }

  .mat-tab-label-active {
    color: #ffffff;
  }
}

.mat-tabs-client {
  .mat-ink-bar {
    background-color: #ffffff !important;
  }
}

.nav-container {
  .mat-ink-bar {
    background-color: #343e48 !important;
  }
}

.mat-tab-client-modal {
  .mat-ink-bar {
    background-color: grey !important;
  }
}

.mat-tab-label,
.mat-tab-label-active {
  margin: 0 auto;
}

/* Styles for tab */

// ========================================

/* Mat dialog styles */

.mat-dialog-container {
  margin: 0 !important;
  padding: 0 !important;
}

.mat-dialog-content {
  overflow: auto;
}

/* Mat dialog styles */

// =========================================

// Mat Button styles

.mat-button-disabled {
  color: #ffffff !important;
  background: #cad2da !important;
}

// Mat Button styles

// =========================================

// Mat Icon styles

.mat-icon {
  vertical-align: middle;
  margin-right: 10px;
}

// Mat Icon styles

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation circle,
.mat-spinner circle {
  .mat-progress-spinner.mat-progress-spinner-indeterminate-animation circle,
  .mat-spinner circle {
    stroke: #72879e;
  }
}

.mat-paginator-range-actions {
  button:disabled {
    background-color: transparent !important;

    .mat-paginator-icon {
      fill: #cad2da;
    }
  }
}

.mat-form-field {
  width: 100%;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;

  &-invalid:not(.form-field-requisites) {
    .mat-form-field-flex {
      border: 1px solid red;
      color: red;
    }
  }

  .mat-form-field-flex {
    display: flex;
    align-items: center;
    padding-top: 0 !important;
    background-color: $c-light-gray;
    max-height: 57px;
    border-radius: 8px;

    .mat-form-field-infix {
      display: flex;

      .mat-datepicker-toggle {
        .mat-button-disabled {
          color: rgba(0, 0, 0, 0.26) !important;
          background: inherit !important;
        }
      }
    }
  }
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background: $c-light-gray;
}

.mat-form-field-appearance-fill {
  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
    margin: 0;

    .mat-error {
      margin-top: 2px;
    }
  }
}

.mat-tooltip {
  background: variables.$c-tooltip-bg;
}

// для TesVatButtonToggleGroupComponent компоненты
.mat-button-toggle-group .mat-button-toggle-label-content {
  line-height: 40px;
}
