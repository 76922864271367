@use 'variables';

.image-holder {
  border: none !important;
}

.datepicker-wrapper {
  margin-top: 8px;
}

ngp-image-picker .place-image .image-holder-loaded img {
  background: none !important;
}

tes-card-layout {
  width: 100%;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #72879e !important;
  border-radius: 4px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #72879e !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 8px !important;
}

.mat-form-field-underline {
  width: 0px !important;
}

@media (max-width: 1480px) {
  .warranty-pay-policy-label-row {
    flex-direction: column;
    gap: 10px;

    &:nth-child(even) {
      align-items: end;
    }
  }
}

@media (max-width: 1270px) {
  .row {
    .col-4 {
      width: 50%;
    }
  }

  .selected-product {
    flex-direction: column;
    height: auto;
  }

  /* extended-warranty */
  .warranty-wrapper {
    flex-direction: column;

    .warranty-left {
      margin-top: 50px;
      padding: 50px 50px 50px 70px !important;
      width: 100% !important;

      .warranty-progress {
        position: relative !important;
        right: 0 !important;
      }
    }

    .warranty-right {
      padding: 50px 50px 50px 70px !important;
      width: 100% !important;

      .warranty-content {
        position: relative !important;
      }
    }

    .warranty-auth-form {
      flex-direction: column;
    }

    .row {
      .col-4,
      .col-6 {
        width: auto;
      }
    }

    .warranty-pay-form-row {
      gap: 15px;
    }

    .warranty-pay-policies {
      flex-direction: column;

      .col-6 {
        width: 100%;
      }
    }

    .success-message-wrapper {
      padding: 50px;
    }
  }

  tes-e-warranty-personal-data {
    .personal-data-content {
      padding: 50px;

      .e-warranty-personal-data-row {
        gap: 20px;
        flex-wrap: wrap;
      }

      .col-4 {
        width: 48% !important;
      }
    }
  }

  .warranty-ready-top {
    max-width: 100%;

    .warranty-ready-top-content {
      .d-flex {
        flex-direction: column;
      }
    }
  }

  .warranty-ready-content {
    padding: 0 30px;
    background: white !important;

    .warranty-ready-left {
      padding-top: 0 !important;
      background: white;

      .warranty-ready-left-content {
        margin: 0 !important;

        .warranty-ready-pdf {
          gap: 20px !important;

          .bg-white {
            input {
              background: rgba(242, 244, 247, 0.9) !important;
            }
          }
        }
      }
    }

    .col-6 {
      width: 100% !important;
    }

    .warranty-ready-right {
      padding: 0 !important;

      .warranty-ready-right-content {
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 1100px) {
  tes-product-processing-layout {
    .row {
      .col-4,
      .col-6 {
        width: 100%;
      }
    }
  }

  /* extended-warranty */
  .warranty-wrapper {
    .warranty-data-content {
      .row {
        .col-4,
        .col-6 {
          width: auto !important;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  /* extended-warranty */
  .warranty-wrapper {
    .warranty-left {
      padding: 50px 40px !important;
    }

    .warranty-right {
      padding: 50px 40px !important;
    }

    .warranty-data-form-row {
      flex-direction: column;
    }

    .warranty-pay-form-row {
      flex-direction: column;
    }

    .col-4,
    .col-6 {
      width: 100% !important;

      .tes-fieldset-readonly {
        max-width: 100% !important;
      }
    }
  }

  tes-e-warranty-personal-data {
    .personal-data-content {
      padding: 30px;
      margin: 0 !important;

      .col-5,
      .col-4 {
        width: 100% !important;
      }
    }
  }

  .warranty-ready-tabs {
    margin-top: 20px !important;

    .warranty-ready-tab {
      span {
        font-size: 18px !important;
      }
    }
  }

  .warranty-ready-pdf {
    flex-direction: column;

    .pdf-download {
      width: 250px !important;
      height: 250px !important;
    }
  }

  .dealers-list {
    padding: 0 !important;

    .dealer-row {
      flex-direction: column;
      gap: 20px;
    }
  }
}

// ИСКЛЮЧЕНИЕ
// позиционирование мат меню в хедере
.mat-menu-panel.dealer-menu {
  border-top: 12px solid transparent;
  background-color: transparent;

  .dealer-menu-body {
    background-color: $c-white;
  }
}

.autocomplete__extended {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    & .mat-form-field-flex {
      border-radius: 8px;
    }

    & .mat-form-field-infix {
      height: 58px;
    }
  }
}

.btn-calc .container {
  height: 57px;
  // _material.scss перебивает этот стиль, поэтому important
  margin-top: 57px !important;
}

.form-group__item--width .tes-fieldset-readonly input {
  width: 83% !important;
}

// глобальные стили mat-icon,
// тут описываем стили и модификаторы
.mat-icon-style {
  color: $c-spanish-gray;
}

// модификатор для иконки действия
.mat-icon__custom-action {
  cursor: pointer;
  //pointer-events: none;
  &:hover {
    opacity: 0.5;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}
