* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

nav,
header,
footer,
aside {
  display: block;
}

a:focus,
a:active {
  outline: none;
}

a,
a:visited,
a:focus {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #343E48 !important;
  -webkit-box-shadow: 0 0 0 30px #f2f4f7 inset !important;
}

td,
th {
  text-align: center;
  vertical-align: middle;
}

select {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-image: url('/assets/images/select_arrow.svg') !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) center !important;
}

// select:active {
//   float: left; // to shift to left
//   transform: rotate(180deg);
// }
