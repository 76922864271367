// типографика прокта
// общие стили текста

// общие стили заголовков и их модификаторы
//h1,
//h2,
//h3,
//h4 {
//}

// общие стили ссылок и их модификаторы
a {
  // ссылка в виде обычного текста
  &.text-box {
    text-decoration: none;
    color: #343e48;
    cursor: pointer;
    &:hover {
      color: #343e48;
      opacity: 0.8;
    }
  }
}

.input-label {
  font-weight: 400;
  font-size: 12px;
  color: #6c757d;
  width: 100%;
  margin-bottom: 8px;
}
