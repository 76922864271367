$c-white: #fff;
$c-black: #000;
$c-light-black: #232323;
$c-milk-white: #e2e2e2;
$c-light-gray: #f2f4f7;
$c-gray: #cad2da;
$c-spanish-gray: #96959e;
$c-dim-gray: #666;
$c-light-slate-gray: #72879e;
$c-dark-cyan: #39434d;
$c-medium-grey: #454545;
$c-arsenic: #343e48;
$c-black-pearl: #0f1923;
$c-red: #ff0000;
$c-light-green: #199f77;
$c-bright-green: #00a1b7;
$c-tooltip-bg: #999fa3;
$c-info-violet: #434391;
