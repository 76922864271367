@use 'sass:map'; /*    Размеры:    xl - Extra Large - >=1200    lg - Large - >=992    md - Medium - >=768    sm - Small - >=576    es - Extra Small - < 576*/
$sizes: (
  'desktop': 1200px,
  'lg': 992px,
  'md': 768px,
  'sm': 576px,
  'es-max': 375px,
  'es': 320px,
);
@mixin desktop {
  @media (max-width: map.get($sizes, 'desktop')) {
    max-width: map.get($sizes, 'desktop');
    @content;
  }
}

@mixin tablet-xl {
  @media (max-width: map.get($sizes, 'lg')) {
    max-width: map.get($sizes, 'lg');
    @content;
  }
}

@mixin tablet-md {
  @media (max-width: map.get($sizes, "md")) {
    max-width: map.get($sizes, "md");
    @content;
  }
}

@mixin mobile-xl {
  @media (max-width: map.get($sizes, 'sm')) {
    max-width: map.get($sizes, 'sm');
    @content;
  }
}

@mixin mobile-mb {
  @media (max-width: map.get($sizes, 'es-max')) {
    max-width: map.get($sizes, 'es-max');
    @content;
  }
}

@mixin mobile-sm {
  @media (max-width: map.get($sizes, 'es')) {
    max-width: map.get($sizes, 'es');
    @content;
  }
}
