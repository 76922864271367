@import 'variables';

// стили таблицы/пагинации и ее модификаторы, на уровне проекта
// общие стили для таблицы
.mat-table {
  width: 100%;
  border-radius: 20px;

  // Действия со строками,
  // добавляем этот модификатор к тадлице в которой кликабельные строки
  // выделенная строка получает модификатор selected-row
  &.table-action-rows {
    cursor: pointer;
    // выделенная строка
    tr.selected-row {
      background: #f6f6f8;
    }
    tr {
      cursor: pointer;
      &:hover {
        background: #f6f6f8;
        opacity: 0.8;
      }
    }
  }

  // хедер
  th,
  .mat-header-cell {
    background: #f2f4f7;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.501286px;
    color: #3b4957;
    font-family: 'Gilroy';
    font-style: normal;
    align-items: center;
    padding: 10px;
  }

  // тело таблицы
  th,
  td,
  .mat-cell {
    border: 1px solid #e2e2e2;
    text-align: center;
    padding: 10px;
    ::ng-deep {
      fieldset {
        margin-top: 0px !important;
        .tes-fieldset-basic-input-filled {
          margin-top: 0px !important;
        }
      }
    }

    // кнопка на странице База знаний
    .read-article-button {
      cursor: pointer;
      border-radius: 10px;
      padding: 10px;
      background: $c-arsenic;
      color: $c-white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    .read-article-button:disabled {
      opacity: 0.5;
    }
  }
}

// общие стили для пагинации
.mat-paginator {
  width: 100%;
}
