// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ngrx-store-primary: mat.define-palette(mat.$indigo-palette);
$ngrx-store-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ngrx-store-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ngrx-store-theme: mat.define-light-theme(
  (
    color: (
      primary: $ngrx-store-primary,
      accent: $ngrx-store-accent,
      warn: $ngrx-store-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ngrx-store-theme);

html {
  font-size: 16px;
}
/* You can add global styles to this file, and also import other style files */
body {
  font-family: 'Gilroy', sans-serif !important;
  width: 100%;
  line-height: 1;
  min-height: 100vh;
  background: #f2f4f7;
  margin: 0;
}

.tes-wrapper {
  padding: 2rem !important;
  min-width: 80%;
  min-height: 80%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -1px 3px 20px 1px rgba(33, 44, 55, 0.08);
  border-radius: 2rem;

  &-title {
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    color: #343e48;
  }

  &-content {
  }

  &-buttons {
    margin-top: 3rem;
    gap: 1rem;
    display: flex;
    justify-content: end;
  }
}

/* Modal windows */
.mat-dialog-title {
}

.mat-dialog-content {
  margin: 0 !important;
  height: max-content;

  .modal-body {
  }
}

.mat-dialog-actions {
  padding: 0 !important;
  margin: 0 0.5rem 15px 0 !important;
}

.mat-dialog-container {
  border-radius: 1.25rem !important;
}

// Input statuses
.success {
}

.danger {
  .text-secondary {
    color: red !important;
  }

  .tes-fieldset-basic-input {
    color: red;
    border: 1px red solid;
  }

  .tes-fieldset-basic-select {
    color: red;
    border: 1px red solid;
  }

  .tes-fieldset-feedback {
    color: red;
    margin-top: 0 !important;
  }
}

// block backgrounds
.tes-bg-info {
  background: #f2f4f7 !important;
}

.tes-bg-success {
  background: rgba(208, 251, 228, 0.4);
}

.tes-text-success {
  color: #199f77;
}

.tes-bg-pending {
  background: #f2f4f7;
}

.tes-text-pending {
  color: black;
}

.loan-offer-bg-success {
  background: #a8d08d;
}

.loan-offer-bg-pending {
  background: #d9d9d9;
}

.loan-offer-bg-reject {
  background: #f7caac;
}

.mat-option,
.mat-select-value-text {
  font-family: 'Gilroy', sans-serif !important;
}

input.mat-input-element[type='date'] {
  line-height: inherit;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.block-name {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #343e48;
}
